import { Cookies } from '@/enums/cookies'
import { ErrorPagesPathname } from '@/enums/error-pages-pathname'
import { ICookieSlugs } from '@/interfaces/cookies/slugs'
import { Cookie } from '@/lib/cookies'
import { identifyUser, setUtmCookies, trackPageView } from '@/lib/tracking'
import Hotjar from '@hotjar/browser'
import type { AppProps } from 'next/app'
import router, { useRouter } from 'next/router'
import { createContext, useEffect } from 'react'
import '../styles/globals.css'
import '../styles/inputRadio.css'
import { generateCssFromTheme, validateSubdomain } from '@/lib/subdomain'
import theme from '../../../packages/config-tailwind/tokens/projectColors.json'
import { subdomainEnv, settingsOperadoras } from '@/operadoras-settings'

const initHotjar = () => {
  if (!Hotjar.isReady()) {
    const HOTJAR_SITE_ID = process.env.NEXT_PUBLIC_HOTJAR_SITE_ID
    const HOTJAR_VERSION = process.env.NEXT_PUBLIC_HOTJAR_VERSION
    Hotjar.init(Number(HOTJAR_SITE_ID), Number(HOTJAR_VERSION))
  }
}

export const CurrentSettingsContext = createContext({})

function MyApp({ Component, pageProps }: AppProps) {
  const { query } = useRouter()

  useEffect(() => {
    const subdomain = query?.subdomain
    if (subdomain !== 'main' && validateSubdomain(subdomain as string)) {
      const env = subdomainEnv[subdomain as keyof typeof subdomainEnv]
      const settings = settingsOperadoras[env]
      const root = document.documentElement
      const styles = generateCssFromTheme(
        theme[settings.colorTheme as keyof typeof theme].primary,
      )
      for (const key in styles) {
        root.style.setProperty(key, styles[key])
      }
    }
  }, [query])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      initHotjar()
    }
  }, [])

  useEffect(() => {
    if (!router.isReady) return

    const setTraits = () => {
      const trackableRoutesWithCookies = [
        'identificacao',
        'confirmacao-de-agendamento',
        ErrorPagesPathname.AgeOutsideAllowedRangeErrorPage,
        ErrorPagesPathname.HealthInsuranceAuthorizationErrorPage,
        ErrorPagesPathname.NameSelectionErrorPage,
        ErrorPagesPathname.RepeatedAppointmentErrorPage,
      ]
      const isRouteTrackableWithCookies = trackableRoutesWithCookies.some((route) =>
        router.pathname.includes(route),
      )

      if (isRouteTrackableWithCookies) {
        const slugs = JSON.parse(Cookie.get(Cookies.SLUGS) || '{}') as ICookieSlugs
        return {
          specialty: slugs.specialtySlug,
          member: slugs.memberSlug,
          unit: slugs.unitSlug,
          type: slugs.serviceType,
        }
      } else {
        const { tipoConsulta, especialidade, unidade, membro } = router.query
        return {
          type: tipoConsulta,
          specialty: especialidade,
          unit: unidade,
          member: membro,
        }
      }
    }

    setUtmCookies(router.query)
    const traits = setTraits()
    identifyUser('', traits)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  useEffect(() => {
    router.events.on('routeChangeComplete', trackPageView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events])

  return <Component {...pageProps} />
}

export default MyApp
