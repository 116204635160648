import { Identifiers } from '@/enums/identifiers'
import { UrlsOperadoras } from '@/enums/urls-operadoras'
import { IOperadoraSettings } from '@/interfaces/operadora-settings'

const getOperadoraUrl = (url: string, subdomain?: string) => {
  const urlSub = subdomain ? `${subdomain}.` : ''
  const isDevelopmentEnvironment = process.env.NODE_ENV === 'development'

  if (isDevelopmentEnvironment) return `http://${urlSub}localhost:3000`

  const isPreviewEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'

  if (isPreviewEnvironment)
    return `https://${urlSub}${process.env.NEXT_PUBLIC_VERCEL_URL}`

  return url
}

const mainSettings: IOperadoraSettings = {
  operatorsId: [0],
  url: getOperadoraUrl(UrlsOperadoras.Main),
  text: {
    operatorName: 'médicos especialistas',
    operatorFor: 'pelo seu plano de saúde',
    operatorNetworkLink: '',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'saludia',
  daysToSchedule: 0,
}

const piwiSettings: IOperadoraSettings = {
  operatorsId: [0],
  url: getOperadoraUrl(UrlsOperadoras.Main, 'piwi'),
  text: {
    operatorName: 'Piwi',
    operatorFor: 'pelo seu convênio de saúde',
    operatorNetworkLink: '',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'piwi',
  daysToSchedule: 0,
  isMiddleware: true,
  middlewareId: 1,
}

const portoSeguroSettings: IOperadoraSettings = {
  operatorsId: [1, 2],
  url: getOperadoraUrl(UrlsOperadoras.PortoSeguro, 'portoseguro'),
  text: {
    operatorName: 'Porto Seguro',
    operatorFor: 'pela Porto Seguro',
    operatorNetworkLink: 'https://www.portoseguro.com.br/fale-conosco/contatos',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'porto-seguro',
  daysToSchedule: 0,
}

const fundacaoSaudeItauSettings: IOperadoraSettings = {
  operatorsId: [2],
  url: getOperadoraUrl(UrlsOperadoras.FundacaoSaudeItau, 'saudeitau'),
  text: {
    operatorName: 'Fundação Saúde Itaú',
    operatorFor: 'pela Fundação Saúde Itaú',
    operatorNetworkLink: 'https://www.portoseguro.com.br/fale-conosco/contatos',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'fundacao-saude-itau',
  daysToSchedule: 0,
}

const omintSettings: IOperadoraSettings = {
  operatorsId: [3],
  url: getOperadoraUrl(UrlsOperadoras.Omint, 'agendamentos'),
  text: {
    operatorName: 'Omint',
    operatorFor: 'pela Omint',
    operatorNetworkLink: 'https://www.omint.com.br/planos-de-saude/fale-conosco/',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'omint',
  daysToSchedule: 0,
}

const kippSaudeSettings: IOperadoraSettings = {
  operatorsId: [4],
  url: getOperadoraUrl(UrlsOperadoras.KippSaude, 'kipp'),
  text: {
    operatorName: 'Kipp Saúde',
    operatorFor: 'pela Kipp Saúde',
    operatorNetworkLink: 'https://api.whatsapp.com/send?phone=5511999315738',
  },
  journey: {
    inPerson: true,
    online: false,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'kipp',
  daysToSchedule: 0,
}

const carePlusSettings: IOperadoraSettings = {
  operatorsId: [5],
  url: getOperadoraUrl(UrlsOperadoras.CarePlus, 'careplus'),
  text: {
    operatorName: 'Care Plus',
    operatorFor: 'pela Care Plus',
    operatorNetworkLink: 'https://www.careplus.com.br/fale-conosco',
  },
  journey: {
    inPerson: true,
    online: false,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'care-plus',
  daysToSchedule: 0,
}

const aliceSettings: IOperadoraSettings = {
  operatorsId: [6],
  url: getOperadoraUrl(UrlsOperadoras.Alice, 'alice'),
  text: {
    operatorName: 'Alice',
    operatorFor: 'pela Alice',
    operatorNetworkLink:
      'https://blog.alice.com.br/plano-de-saude/alice-agora-atendimento-online/',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'alice',
  daysToSchedule: 0,
}

const samiSettings: IOperadoraSettings = {
  operatorsId: [7],
  url: getOperadoraUrl(UrlsOperadoras.Sami, 'sami'),
  text: {
    operatorName: 'Sami',
    operatorFor: 'pela Sami',
    operatorNetworkLink: 'https://ajuda.samisaude.com.br/como-falar-com-a-sami',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'sami',
  daysToSchedule: 1,
}

const abasSettings: IOperadoraSettings = {
  operatorsId: [8],
  url: getOperadoraUrl(UrlsOperadoras.Abas, 'abas'),
  text: {
    operatorName: 'Abas',
    operatorFor: 'pela Abas',
    operatorNetworkLink: 'https://www.saudeabas.org.br/contato',
  },
  journey: {
    inPerson: true,
    online: false,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'abas',
  daysToSchedule: 0,
}

const segurosUnimedSettings: IOperadoraSettings = {
  operatorsId: [9],
  url: getOperadoraUrl(UrlsOperadoras.SegurosUnimed, 'segurosunimed'),
  text: {
    operatorName: 'Seguros Unimed',
    operatorFor: 'pela Seguros Unimed',
    operatorNetworkLink: 'https://www.segurosunimed.com.br/fale-conosco',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'seguros-unimed',
  daysToSchedule: 0,
}

const sulAmericaSettings: IOperadoraSettings = {
  operatorsId: [10],
  url: getOperadoraUrl(UrlsOperadoras.SulAmerica, 'sulamerica'),
  text: {
    operatorName: 'SulAmérica',
    operatorFor: 'pela SulAmérica',
    operatorNetworkLink: 'https://portal.sulamericaseguros.com.br/canaisdeatendimento/',
  },
  journey: {
    inPerson: true,
    online: true,
    walkIn: false,
  },
  identifierName: Identifiers.CPF,
  colorTheme: 'sulamerica',
  daysToSchedule: 0,
}

export const settingsOperadoras: Record<string, IOperadoraSettings> = {
  'porto-seguro': portoSeguroSettings,
  'fundacao-saude-itau': fundacaoSaudeItauSettings,
  omint: omintSettings,
  'kipp-saude': kippSaudeSettings,
  'care-plus': carePlusSettings,
  alice: aliceSettings,
  sami: samiSettings,
  abas: abasSettings,
  piwi: piwiSettings,
  main: mainSettings,
  'seguros-unimed': segurosUnimedSettings,
  sulamerica: sulAmericaSettings,
}

export const subdomainEnv: Record<string, string> = {
  portoseguro: 'porto-seguro',
  agendamentos: 'omint',
  careplus: 'care-plus',
  fundacaosaudeitau: 'fundacao-saude-itau',
  alice: 'alice',
  sami: 'sami',
  abas: 'abas',
  piwi: 'piwi',
  main: 'main',
  segurosunimed: 'seguros-unimed',
  sulamerica: 'sulamerica',
}

export const settingsOperadorasById: Record<number, IOperadoraSettings> = {
  0: mainSettings,
  1: portoSeguroSettings,
  2: portoSeguroSettings,
  3: omintSettings,
  4: kippSaudeSettings,
  5: carePlusSettings,
  6: aliceSettings,
  7: samiSettings,
  8: abasSettings,
  9: segurosUnimedSettings,
  10: sulAmericaSettings,
}
